.container-csat {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;

}

.container-csat-child {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  max-width: 68.75rem;
  /* height: 100vh; */
  /* padding: 4.375rem */
}

/* .rating{
  width:100%;
  max-width: 60rem;
} */

@media (max-width: 768px) {
  .container-csat {
    min-height: 60vh;
  }

  .container-csat-child {
    max-width: 50rem;
  }

  .container-csat-child button {
    width: 100%;
    font-size: 0.875rem;
  }

  .container-csat-child Rating {
    size: 75;
  }
}
.container-csat-child button {
  width: 100%;
}
.container-csat-child button {
  width: 100%;
  cursor: pointer;

}

.container-csat-child button:disabled {
  cursor: not-allowed;
  opacity: 0.3;
  background-color: #ccc;
  color: black;
}

@media (max-width: 576px) {
  .container-csat {
    min-height: 40vh;
  }

  .container-csat-child {
    max-width: 30rem;
  }

  .container-csat-child button {
    width: 100%;
    font-size: 0.75rem;
  }

  .container-csat-child Rating {
    size: 50;
  }
}

@media (max-width: 390px) {
  .container-csat {
    min-height: 40vh;
  }

  .container-csat-child {
    max-width: 20rem;
  }

  .container-csat-child button {
    width: 100%;
    font-size: 0.5rem;
  }

  .container-csat-child Rating {
    size: 40;
  }

  .rating{
    max-width:21.875rem;
  }
}