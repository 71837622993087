img {
  max-height: 100vh;
  max-width: 100%;
}
body {
  background-color: #f5f5f5;
  font-family: "Plus Jakarta Sans";
  margin: 0;
  padding: 0;
  overflow: visible;
}
.container-split {
  display: flex;
  direction: column;
  justify-content: center;
  height: 100vh;
}
.container-split > div {
  flex-basis: 50%;
}
.container-split-right img {
  display: flex;
  align-items: left;
  justify-content: left;
  width: 100%;
  min-height: 100vh;
  overflow: hidden;
  flex-shrink: 0;
  background-size: cover;
  /* max-height: calc(100% - 20px); Adjust the value as needed */
  /* object-fit: contain; */
}

.container-split-left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5.0625rem 6.3125rem 5rem 7.3125rem;
}
.main {
  height: 100vh;
}
.content {
  max-width: 31rem;
}
.container-checkbox {
  display: flex;
  /* direction: column; */
  position: relative;

  font-size: 1rem;
  letter-spacing: 0.0094rem;
  line-height: 1.5rem;
  font-weight: 500;
  color: #666;
  /* display: inline-block; */
}

.span-links {
  cursor: pointer;
  color: #6758ff;
  font-weight: 600;
  text-decoration: underline;
}
.meeting {
  background: linear-gradient(68.86deg, #6758ff, #46d7d0 91.67%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.socialButtons {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 1.5rem;
  text-align: left;
  font-size: 1rem;
  color: #fff;
}
.buttons {
  cursor: pointer;
  border-radius: 0.625rem;
  background-color: #0f1114;
  box-shadow: 0rem 0.25rem 0.625rem rgba(0, 0, 0, 0.2);
  max-width: 31rem;
  width: 100%;
  height: 3.5rem;
  display: flex;
  flex-direction: row;
  padding: 0.5rem 1.5rem;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  gap: 0.75rem;
  color: white;
}

@media (max-width: 960px) {
  .container-split {
    flex-direction: column;
  }

  .container-split-right {
    order: 1;
  }
  .container-split-right img {
    display: flex;
    align-items: center;
    justify-content: center;
    /* width: 100%; */
    height: 10px;
    /* overflow:hidden; */
    /* flex-shrink: 0; */
    /* background-size: cover; */
  }

  .container-split-left {
    order: 2;
    padding: 2.0625rem 2.3125rem 5rem 2.3125rem;
    max-width: 39rem;
  }
}
