.container-thankyou {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;

}

.container-thankyou-child {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* height: 100vh; */
  padding: 4.375rem;
}

.container-thankyou-child img {
  max-width: fit-content;
}

.container-thankyou-text {
  max-width: 69.875rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  padding: 0 0 0.625rem;
  font-size: 1.2rem;
  line-height: 2.75rem;
}

