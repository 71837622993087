.large-text-field-container {
  position: relative;
  width: 70%;
  /* padding: 1rem; */
}

.large-text-field {
  width: 100%;
  height: 12.5rem;
  border: 0.0625rem solid #ccc;
  border-radius: 0.25rem;
  padding: 0.5rem;
  font-size: 1rem;
}

.required-star {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  color: red;
  font-size: 1.25rem;
}

.word-counter {
  position: absolute;
  bottom: 0.5rem;
  left: 0.5rem;
  font-size: 0.875rem;
  color: #888;
}
