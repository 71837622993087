.container-success {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.tomobo {
  background: linear-gradient(68.86deg, #6758ff, #46d7d0 91.67%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.container-success-child {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* height: 100vh; */
  padding: 4.375rem;
}
.container-success-text {
  max-width: 69.875rem;
  min-height: 4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  padding: 0 0 0.625rem;
  font-size: 1.2rem;
  /* line-height: 2.75rem; */
}
.container-success-text .p {
  font-size: 1.125rem;
}

button {
  cursor: pointer;
  border: 0.125rem solid #6758ff;
  padding: 0.5rem 1.5rem;
  font-family: Plus Jakarta Sans;
  background-color: transparent;
  border-radius: 0.625rem;
  box-sizing: border-box;
  max-width: 17.875rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.75rem;
  color: #6758ff;
}
button:hover {
  background-color: #6758ff;
  color: #fff;
}

.rocket {
  position: relative;
  max-width: 30%;
  max-height: 30%;
  /* object-fit: cover; */
}
