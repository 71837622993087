.graphic-child {
  top: 0;
  left: 0;
  background: linear-gradient(180deg, #6758ff, #46d7d0);
  height: 122.42px;
}
.graphic-child,
.graphic-inner,
.graphic-item {
  position: absolute;
  width: 5.72px;
}
.graphic-item {
  top: 0;
  left: 12px;
  background: linear-gradient(180deg, #6758ff, #46d7d0);
  height: 62.4px;
}
.graphic-inner {
  top: 101.46px;
  left: 12.38px;
  background-color: #46d7d0;
  height: 20.96px;
}
.graphic-child1,
.rectangle-div {
  position: absolute;
  left: 24.77px;
  width: 5.72px;
}
.rectangle-div {
  top: 0;
  background: linear-gradient(180deg, #6758ff, #46d7d0);
  height: 57.16px;
}
.graphic-child1 {
  top: 107.18px;
  background-color: #46d7d0;
  height: 15.24px;
}
.graphic-child2,
.graphic-child3 {
  position: absolute;
  left: 37.15px;
  width: 5.72px;
}
.graphic-child2 {
  top: 0;
  background: linear-gradient(180deg, #6758ff, #46d7d0);
  height: 62.4px;
}
.graphic-child3 {
  top: 101.46px;
  background-color: #46d7d0;
  height: 20.96px;
}
.graphic-child4,
.graphic-child5,
.graphic-child6 {
  position: absolute;
  top: 0;
  left: 49.54px;
  background: linear-gradient(180deg, #6758ff, #46d7d0);
  width: 5.72px;
  height: 122.42px;
}
.graphic-child5,
.graphic-child6 {
  left: 61.9px;
}
.graphic-child6 {
  left: 74.28px;
}
.ellipse-div {
  position: absolute;
  top: 66.69px;
  left: 12.38px;
  border-radius: 50%;
  background-color: #6758ff;
  width: 30.49px;
  height: 30.49px;
}
.graphic1,
.main-graphic {
  width: 80px;
  height: 122.42px;
}
.graphic1 {
  position: absolute;
  top: 0;
  left: 0;
}
.main-graphic {
  position: relative;
}
.graphic {
  height: 170px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
}
.metaphy-icon {
  position: relative;
  width: 280.83px;
  height: 86.71px;
  display: none;
}
.logo {
  position: absolute;
  top: 0;
  left: calc(50% - 40px);
  height: 124px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 12px;
}
.metaphy-labs,
.privacy-policy1 {
  position: relative;
  line-height: 24px;
}
.metaphy-labs {
  letter-spacing: 1px;
  font-weight: 500;
}
.privacy-policy1 {
  font-size: 32px;
  letter-spacing: 0.15px;
}
.metaphy-labs-parent {
  position: absolute;
  top: 151px;
  left: calc(50% - 720px);
  background-color: #6758ff;
  width: 1440px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: 30px 588px;
  box-sizing: border-box;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
}
.this-privacy-policy {
  margin: 0;
}
.what-we-mean {
  font-weight: 800;
}
.what-we-mean-by-personal-infor {
  margin: 0;
  padding-left: 32px;
}
.information-you-provide {
  margin: 0;
  font-weight: 800;
}
.information-you-provide-in-ord {
  margin-bottom: 0;
}
.this-privacy-policy-container1 {
  line-break: anywhere;
  width: 100%;
}
.this-privacy-policy-container {
  position: absolute;
  top: 299px;
  left: calc(50% - 656px);
  font-size: inherit;
  letter-spacing: 0.15px;
  color: #000;
  display: flex;
  align-items: center;
  width: 1312px;
}
.privacy-policy {
  position: relative;
  border-radius: 24px;
  background-color: #f4f9ff;
  box-shadow: 20px 20px 80px rgba(0, 0, 0, 0.14);
  width: 100%;
  height: 1024px;
  overflow: visible;
  text-align: left;
  font-size: 16px;
  color: #fff;
  font-family: "Plus Jakarta Sans";
}
