.container-failure {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 50px;
}
.container-failure-child {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.container-failure-child h1 {
  max-width: 43.75rem;
}
.container-failure-child img {
  width: 60%;
}
.container-failure-child button {
  color: blue;
  width: 100%;
}
