.graphic-child7,
.graphic-child8 {
  position: absolute;
  top: 0;
  left: 0;
  background: linear-gradient(180deg, #6758ff, #46d7d0);
  width: 5.72px;
  height: 122.42px;
}
.graphic-child8 {
  left: 12px;
  height: 62.4px;
}
.graphic-child9 {
  position: absolute;
  top: 101.46px;
  left: 12.38px;
  background-color: #46d7d0;
  width: 5.72px;
  height: 20.96px;
}
.graphic-child10,
.graphic-child11 {
  position: absolute;
  left: 24.77px;
  width: 5.72px;
}
.graphic-child10 {
  top: 0;
  background: linear-gradient(180deg, #6758ff, #46d7d0);
  height: 57.16px;
}
.graphic-child11 {
  top: 107.18px;
  background-color: #46d7d0;
  height: 15.24px;
}
.graphic-child12,
.graphic-child13 {
  position: absolute;
  left: 37.15px;
  width: 5.72px;
}
.graphic-child12 {
  top: 0;
  background: linear-gradient(180deg, #6758ff, #46d7d0);
  height: 62.4px;
}
.graphic-child13 {
  top: 101.46px;
  background-color: #46d7d0;
  height: 20.96px;
}
.graphic-child14,
.graphic-child15,
.graphic-child16 {
  position: absolute;
  top: 0;
  left: 49.54px;
  background: linear-gradient(180deg, #6758ff, #46d7d0);
  width: 5.72px;
  height: 122.42px;
}
.graphic-child15,
.graphic-child16 {
  left: 61.9px;
}
.graphic-child16 {
  left: 74.28px;
}
.graphic-child17 {
  position: absolute;
  top: 66.69px;
  left: 12.38px;
  border-radius: 50%;
  background-color: #6758ff;
  width: 30.49px;
  height: 30.49px;
}
.graphic3,
.main-graphic1 {
  width: 80px;
  height: 122.42px;
}
.graphic3 {
  position: absolute;
  top: 0;
  left: 0;
}
.main-graphic1 {
  position: relative;
}
.graphic2 {
  height: 170px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
}
.metaphy-icon1 {
  position: relative;
  width: 280.83px;
  height: 86.71px;
  display: none;
}
.logo1 {
  position: absolute;
  top: 0;
  left: calc(50% - 40px);
  height: 124px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 12px;
}
.agreement,
.terms-of-service {
  position: relative;
  line-height: 24px;
  color: white;
}
.agreement {
  letter-spacing: 1px;
  font-weight: 500;
}
.terms-of-service {
  font-size: 32px;
  letter-spacing: 0.15px;
}
.agreement-parent {
  position: absolute;
  top: 151px;
  left: calc(50% - 720px);
  background-color: #6758ff;
  width: 1440px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: 30px 588px;
  box-sizing: border-box;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
}
.these-terms-of {
  margin: 0;
}
.our-services {
  margin: 0;
  font-weight: 800;
}
.disclaims-any-responsibility-f {
  margin-bottom: 0;
}
.is-not-involved-in-the-creatio {
  margin: 0;
  padding-left: 32px;
}
.these-terms-of-container1 {
  line-break: anywhere;
  width: 100%;
}
.these-terms-of-container {
  position: absolute;
  top: 299px;
  left: calc(50% - 656px);
  font-size: inherit;
  letter-spacing: 0.15px;
  color: #000;
  display: flex;
  align-items: center;
  width: 1312px;
}
.terms-and-conditions {
  position: relative;
  border-radius: 24px;
  background-color: #f4f9ff;
  box-shadow: 20px 20px 80px rgba(0, 0, 0, 0.14);
  width: 100%;
  height: 1024px;
  overflow: visible;
  text-align: left;
  font-size: 16px;
  color: #fff;
  font-family: "Plus Jakarta Sans";
}
